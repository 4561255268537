import React from "react";
import ApiSrv from "../ApiSrv";

const Api = new ApiSrv();

export default class MarketLogs extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			markets: [],
			marketID: 0
		};
	}

	componentDidMount() {
		Api.getMethod("market", "get_list")
			.then(data => {
				if (data.Status == 1) {
					this.setState({markets: data.Data});
				}
				else {
					alert(data.ErrMsg);
				}
			})
			.catch(ex =>  alert(ex));
	}

	render() {
		return (
			<section className="hero">
				<div className="container">
					<div className="select">
						<select value={this.state.marketID} onChange={(ev) => this.setState({ marketID: ev.target.value })}>
							<option value={0}>(Select a market)</option>
							{
								this.state.markets.map(mk => {
									return (
										<option key={mk.ID} value={mk.ID}>{mk.market_name}</option>
									)
								})
							}
						</select>
					</div>
				</div>
				<div className="container">
					
				</div>
			</section>
		)
	}
};