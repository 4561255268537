import React from "react";
import EditModal from "../components/subscriptions/editmodal";
import ApiSrv from "../ApiSrv";

const Api = new ApiSrv();
var loading = false;

export default class Subscriptions extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
            accounts: [],
            categories: [],
			subs: [],
			showEditModal: false,
			selected_subscription: undefined
		};
	};

	componentDidMount() {
        if (loading) {
            return;
        }

        Promise.all([
            this.getAccountsList(),
            this.getCategoriesList(),
            this.getSubscriptionsList(),
        ])
            .then(data => {
                console.log(data);
                this.setState({accounts: data[0], categories: data[1], subs: data[2]});
                loading = false;
            })
            .catch(ex => alert(ex));

		loading = true;
	}

    getAccountsList() {
        return new Promise((resolve, reject) => {
            Api.getMethod("accounts", "get_list", { show_all: 1 })
                .then(data => {
                    if (data.Status == 1) {
                        resolve(data.Data);
                    }
                    else {
                        reject(data.ErrMsg);
                    }
                })
                .catch(ex => reject(ex));
        });
    };

    getSubscriptionsList() {
        return new Promise((resolve, reject) => {
            Api.getMethod("subscriptions", "get_list")
                .then(data => {
                    if (data.Status == 1) {
                        resolve(data.Data);
                    }
                    else {
                        reject(data.ErrMsg);
                    }
                })
                .catch(ex => reject(ex));
        });
    }

    getCategoriesList() {
        return new Promise((resolve, reject) => {
            Api.getMethod("categories", "get_list")
                .then(data => {
                    if (data.Status == 1) {
                        resolve(data.Data);
                    }
                    else {
                        reject(data.ErrMsg);
                    }
                })
                .catch(ex => reject(ex));
        });
    }

	render() {
		return (
			<section className="hero">
				{
					this.state.showEditModal &&
					<EditModal	data={this.state.selected_subscription}
								subsList={this.state.subs}
								closeMe={(result, index) => this.endEdit(result, index)} />
				}
				<table className="table">
					<thead>
						<tr>
							<th>ID</th>
							<th>Name</th>
							<th>Account</th>
							<th>Category</th>
							<th>Active</th>
							<th>Is Monthly?</th>
                            <th>Is Yearly?</th>
                            <th>Day</th>
                            <th>Month</th>
							<th>
								<button className="button is-primary" onClick={() => this.setState({showEditModal: true})}>Add</button>
							</th>
						</tr>
					</thead>
					<tbody>
						{/* {
							this.state.subs.map((sub, index) => {
								return (
									<tr key={sub.ID}>
										<td>{sub.ID}</td>
										<td>{sub.sub_name}</td>
										<td>{acc.account_name}</td>
										<td>{acc.cut_off_day}</td>
										<td>{acc.due_day}</td>
										<td>{acc.paying_account_ID && this.state.accounts.find(a => { return a.ID == acc.paying_account_ID }).account_name}</td>
										<td>{acc.active == 1 ? "Yes" : "No"}</td>
										<td>
											<button className="button is-info" onClick={() => this.setState({selected_account: {account: acc, index}, showEditModal: true})}>Edit</button>
										</td>
									</tr>
								)
							})
						} */}
					</tbody>
				</table>
			</section>
		)
	};

	endEdit(result, index) {
		let cop = [...this.state.accounts];
		
		if (result) {
			if (index) {
				cop[index].account_name = result.account_name;
				cop[index].cut_off_day = result.cut_off_day;
				cop[index].due_day = result.due_day;
				cop[index].paying_account_ID = result.paying_account_ID;
				cop[index].image_name = result.image_name;
				cop[index].active = result.active;
			}
			else {
				cop.push({
					ID: result.ID,
					account_name: result.account_name,
					cut_off_day: result.cut_off_day,
					due_day: result.due_day,
					paying_account_ID: result.paying_account_ID,
					image_name: result.image_name,
					active: result.active
				});
			}
		}

		this.setState({ showEditModal: false, selected_account: undefined, accounts: cop });
	};
};