import React from "react";
import ApiSrv from "../ApiSrv";
import numbro from "numbro";

const Api = new ApiSrv();
numbro.setLanguage("en-US");

export default class CashReceipts extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			thirds: [],

			third_ID: 0,
			transactions: [],

			showModal: false,
			selectedTransaction: undefined,
			datetime: "",
			amount: 0,
			notes: ""
		};
	};

	componentDidMount() {
		Api.getMethod("thirds", "get_list")
			.then(data => {
				if (data.Status == 1) {
					this.setState({thirds: data.Data});
				}
				else {
					alert(data.ErrMsg);
				}
			})
			.catch(ex => alert(ex));
	};

	render() {
		return (
			<section className="hero">
				<div className={"modal" + (this.state.showModal ? " is-active" : "")}>
					<div className="modal-background"></div>
					<div className="modal-card">
						<header className="modal-card-head">
							<p className="modal-card-title">Add Cash Receipt</p>
							<button className="delete" aria-label="close" onClick={() => this.setState({showModal: false})}></button>
						</header>
						<section className="modal-card-body">
							<div className="columns">
								<div className="column">
									<label className="label">Total</label>
								</div>
								<div className="column">
									<span>$ {numbro(this.state.selectedTransaction?.total).format({thousandSeparated: true, mantissa: 2})}</span>
								</div>
								<div className="column">
									<label className="label">Payed</label>
								</div>
								<div className="column">
									<span>$ {numbro(this.state.selectedTransaction?.payed).format({thousandSeparated: true, mantissa: 2})}</span>
								</div>
								<div className="column">
									<label className="label">Total Due</label>
								</div>
								<div className="column">
									<span>$ {numbro(this.state.selectedTransaction?.due).format({thousandSeparated: true, mantissa: 2})}</span>
								</div>
							</div>
							<div className="columns">
								<div className="column">
									<label className="label">Datetime</label>
								</div>
								<div className="column">
									<input className="input" type="datetime-local" value={this.state.datetime} onChange={(ev) => this.setState({datetime: ev.target.value})} />
								</div>
							</div>
							<div className="columns">
								<div className="column">
									<label className="label">Amount</label>
								</div>
								<div className="column">
									<input className="input" type="number" value={this.state.amount} onChange={(ev) => this.setState({amount: ev.target.value})} />
								</div>
							</div>
							<div className="columns">
								<div className="column">
									<label className="label">Notes</label>
								</div>
								<div className="column">
									<input className="input" type="text" value={this.state.notes} onChange={(ev) => this.setState({notes: ev.target.value})} />
								</div>
							</div>
						</section>
						<footer className="modal-card-foot">
							<button className="button is-success" onClick={() => this.save()}>Save</button>
							<button className="button" onClick={() => this.setState({showModal: false})}>Cancel</button>
						</footer>
					</div>
				</div>

				<div className="columns">
					<div className="column is-1">
						<label className="label">Third</label>
					</div>
					<div className="column is-1">
						<div className="select">
							<select value={this.state.third_ID} onChange={(ev) => this.setState({ third_ID: ev.target.value, transactions: [] })}>
								<option value={0}></option>
								{
									this.state.thirds.map(th => { return ( <option value={th.ID}>{th.third_name}</option> ) })
								}
							</select>
						</div>
					</div>
					<div className="column is-1">
						<button className="button is-info" onClick={() => this.search()}>
							<ion-icon name="search-outline"></ion-icon>
							Search
						</button>
					</div>
				</div>
				<table className="table">
					<thead>
						<tr>
							<th>ID</th>
							<th>Account</th>
							<th>Datetime</th>
							<th>Notes</th>
							<th>Total</th>
							<th>Payed</th>
							<th>Due</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{
							this.state.transactions.map(tr => {
								return (
									<tr key={tr.ID}>
										<td>{tr.ID}</td>
										<td>{tr.account_name}</td>
										<td>{tr.datetime_transaction}</td>
										<td>{tr.notes}</td>
										<td>$ {numbro(tr.total).format({thousandSeparated: true, mantissa: 2})}</td>
										<td>$ {numbro(tr.payed).format({thousandSeparated: true, mantissa: 2})}</td>
										<td>$ {numbro(tr.due).format({thousandSeparated: true, mantissa: 2})}</td>
										<td>
											<a href="#" onClick={() => this.applyCashreceipt(tr)}>Apply Cash Receipt</a>
										</td>
									</tr>
								)
							})
						}
						<tr>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td><strong>$ {numbro(this.sumTotal()).format({thousandSeparated: true, mantissa: 2})}</strong></td>
							<td><strong>$ {numbro(this.sumPayed()).format({thousandSeparated: true, mantissa: 2})}</strong></td>
							<td><strong>$ {numbro(this.sumDue()).format({thousandSeparated: true, mantissa: 2})}</strong></td>
						</tr>
					</tbody>
				</table>
			</section>
		)
	};

	search() {
		Api.getMethod("transactions", "search_non_payed_transactions_by_third", { third_ID: this.state.third_ID })
			.then(data => {
				if (data.Status == 1) {
					this.setState({transactions: data.Data});
				}
				else {
					alert(data.ErrMsg);
				}
			})
			.catch(ex => alert(ex));
	};

	applyCashreceipt(selectedTransaction) {
		this.setState({
			showModal: true,
			selectedTransaction,
			datetime: "",
			amount: 0,
			notes: ""
		});
	};

	save() {
		let p = {
			third_ID: this.state.third_ID,
			transaction_ID: this.state.selectedTransaction.ID,
			total: this.state.amount,
			notes: this.state.notes
		};

		if (this.state.datetime != "")
			p.datetime_receipt = this.state.datetime;

		Api.postMethod("cash_receipts", "insert_cash_receipt", p)
			.then(data => {
				if (data.Status == 1) {
					this.setState({showModal: false});
					this.search();
				}
				else {
					alert(data.ErrMsg);
				}
			})
			.catch(ex => alert(ex));
	};

	sumTotal() {
		if (this.state.transactions.length > 0 ) {
			let total = 0;

			this.state.transactions.forEach(t => {
				total += parseFloat(t.total);
			});

			return total;
		}
		else {
			return 0;
		}
	};

	sumPayed() {
		if (this.state.transactions.length > 0 ) {
			let total = 0;

			this.state.transactions.forEach(t => {
				total += parseFloat(t.payed);
			});

			return total;
		}
		else {
			return 0;
		}
	};

	sumDue() {
		if (this.state.transactions.length > 0 ) {
			let total = 0;

			this.state.transactions.forEach(t => {
				total += parseFloat(t.due);
			});

			return total;
		}
		else {
			return 0;
		}
	}
};