import React from "react";
import EditModal from "../components/categories/editmodal";
import ApiSrv from "../ApiSrv";

const Api = new ApiSrv();

export default class Categories extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			categories: [],
			showEditModal: false,
			selected_category: undefined
		};
	};

	componentDidMount() {
		Api.getMethod("categories", "get_list", { get_all: 1 })
			.then(data => {
				if (data.Status == 1) {
					this.setState({categories: data.Data});
				}
				else {
					alert(data.ErrMsg);
				}
			})
			.catch(ex => alert(ex));
	}

	render() {
		return (
			<section className="hero">
				{
					this.state.showEditModal &&
					<EditModal	data={this.state.selected_category}
								closeMe={(result, index) => this.endEdit(result, index)} />
				}
				<table className="table">
					<thead>
						<tr>
							<th>Code</th>
							<th>Name</th>
							<th>Monthly Budget</th>
							<th>Icon</th>
							<th>Active</th>
							<th>
								<button className="button is-primary" onClick={() => this.setState({showEditModal: true})}>Add</button>
							</th>
						</tr>
					</thead>
					<tbody>
						{
							this.state.categories.map((cat, index) => {
								return (
									<tr key={cat.cat_code}>
										<td>{cat.cat_code}</td>
										<td>{cat.cat_name}</td>
										<td>{cat.monthly_budget ? cat.monthly_budget : 0}</td>
										<td>{cat.icon}</td>
										<td>{cat.active == 1 ? "Yes" : "No"}</td>
										<td>
											<button className="button is-info" onClick={() => this.setState({selected_category: {category: cat, index}, showEditModal: true})}>Edit</button>
										</td>
									</tr>
								)
							})
						}
					</tbody>
				</table>
			</section>
		)
	};

	endEdit(result, index) {
		let cop = [...this.state.categories];
		
		if (result) {
			if (index) {
				cop[index].cat_code = result.cat_code;
				cop[index].cat_name = result.cat_name;
				cop[index].monthly_budget = result.monthy_budget;
				cop[index].icon = result.icon;
			}
			else {
				cop.push({
					cat_code: result.cat_code,
					cat_name: result.cat_name,
					monthly_budget: result.monthly_budget,
					icon: result.icon
				});
			}
		}

		this.setState({ showEditModal: false, selected_category: undefined, categories: cop });
	};
};