export default class ApiSrv {
    constructor() {
        this.Host = "https://ppp.sgserch.com/api/";
        // this.Host = "http://localhost:9292/api/";
    }

    getMethod(api, method, params) {
        let theUrl = `${this.Host}${api}.php/${method}`;
    
        if (params)
            theUrl += "?" + new URLSearchParams(params).toString();
    
        return new Promise((resolve, reject) => {
            fetch(theUrl, {
                headers: {
                    KENTOKENFC: window.localStorage.getItem("tkn")
                }
            })
                .then(response => response.json())
                .then(data => {
                    if (data.Status == -100) {
                        window.location.href = "/";
                    }
                    else {
                        resolve(data);
                    }
                })
                .catch(ex => reject(ex));
        });
    }

    postMethod(api, method, params) {
        let theUrl = `${this.Host}${api}.php/${method}`;

	    params = params || {};

        return new Promise((resolve, reject) => {
            fetch(theUrl, {
                method: "POST",
                body: JSON.stringify(params),
                headers: {
                    'Content-Type': 'application/json',
                    KENTOKENFC: window.localStorage.getItem("tkn")
                }
            })
                .then(response => response.json())
                .then(data => {
                    if (data.Status == -100) {
                        window.location.href = "/";
                    }
                    else {
                        resolve(data);
                    }
                })
                .catch(ex => reject(ex));
        });
    }
}