import React from "react";
import ApiSrv from "../ApiSrv";

const Api = new ApiSrv();

export default class Markets extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			markets: [],
			showModal: false,
			index: undefined,
			ID: undefined,
			market_name: undefined
		};
	}

	componentDidMount() {
		this.loadData();
	}

	render() {
		return (
			<section className="hero">
				<div className={"modal" + (this.state.showModal ? " is-active" : "")}>
					<div className="modal-background"></div>
					<div className="modal-card">
						<header className="modal-card-head">
							<p className="modal-card-title">Edit</p>
							<button className="delete" aria-label="close" onClick={() => this.setState({showModal: false})}></button>
						</header>
						<section className="modal-card-body">
							<input className="input" type="text" value={this.state.market_name} onChange={(ev) => this.setState({market_name: ev.target.value})}  />
						</section>
						<footer className="modal-card-foot">
							<button className="button is-success" onClick={() => this.save()}>Save</button>
							<button className="button" onClick={() => this.setState({showModal: false})}>Cancel</button>
						</footer>
					</div>
				</div>

				<table className="table">
					<thead>
						<tr>
							<th>Market Name</th>
							<th>
								<button className="button is-primary" onClick={() => this.setState({ showModal: true, ID: 0, market_name: "" })}>
									Add
								</button>
							</th>
						</tr>
					</thead>
					<tbody>
						{
							this.state.markets.map((mk, index) => {
								return (
									<tr key={index}>
										<td>{mk.market_name}</td>
										<td>
											<button className="button is-info" onClick={() => this.edit(index)}>Edit</button>
										</td>
									</tr>
								)
							})
						}
					</tbody>
				</table>
			</section>
		)
	};

	edit(index) {
		this.setState({
			ID: this.state.markets[index].ID,
			market_name: this.state.markets[index].market_name,
			index,
			showModal: true
		});
	};

	loadData() {
		Api.getMethod("market", "get_list")
			.then(data => {
				if (data.Status == 1) {
					this.setState({markets: data.Data});
				}
				else {
					alert(data.ErrMsg);
				}
			})
			.catch(ex => alert(ex));
	};

	save() {
		let method = this.state.ID == 0 ? "insert" : "update";

		let p = {
			market_name: this.state.market_name
		};

		if (this.state.ID != 0) {
			p.ID = this.state.ID;
		}

		Api.postMethod("market", `${method}_market`, p)
			.then(data => {
				if (data.Status == 1) {
					let cop = [...this.state.markets];

					if (method == "insert") {
						cop.push(data.Data);
					}
					else {
						cop[this.state.index].ID = data.Data.ID;
						cop[this.state.index].market_name = data.Data.market_name;
					}

					this.setState({markets: cop, ID: 0, index: undefined, market_name: undefined, showModal: false});
				}
				else {
					alert(data.ErrMsg);
				}
			})
			.catch(ex => alert(ex));
	}

}