import React from "react";
import ApiSrv from "../ApiSrv";

const Api = new ApiSrv();

export default class Pets extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			pets: []
		};
	};

	componentDidMount() {
		Api.getMethod("pet", "get_list", {include_age: true})
			.then(data => {
				if (data.Status == 1) {
					this.setState({pets: data.Data});
				}
				else {
					alert(data.ErrMsg);
				}
			})
			.catch(ex => alert(ex));
	};

	render() {
		return (
			<section className="hero">
				<table className="table">
					<thead>
						<tr>
							<th></th>
							<th>Name</th>
							<th>Birth Date</th>
							<th>Last Weight</th>
							<th>Race</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{
							this.state.pets.map(p => {
								return (
									<tr key={p.ID}>
										<td>
											<figure className="image is-64x64">
												<img src={`https://ppp.sgserch.com/p/${p.pet_name.toLowerCase()}.jpg`} />
											</figure>
										</td>
										<td>{p.pet_name}</td>
										<td>{p.birthdate} ({p.age} years)</td>
										<td>{p.last_weight}</td>
										<td>{p.race}</td>
										<td></td>
									</tr>
								)
							})
						}
					</tbody>
				</table>
			</section>
		)
	};
};