import React from "react";
import ApiSrv from "../ApiSrv";
import numbro from "numbro";

const Api = new ApiSrv();
numbro.setLanguage("en-US");

export default class CashReceiptDetail extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			receipts: []
		};
	};

	componentDidMount() {
		Api.getMethod("cash_receipts", "get_by_transaction", {transaction_ID: this.props.transaction_ID})
			.then(data => {
				if (data.Status == 1) {
					this.setState({receipts: data.Data});
				}
				else {
					alert(data.ErrMsg);
				}
			})
			.catch(ex => alert(ex));
	};

	render() {
		return (
			<div className="modal is-active">
				<div className="modal-background"></div>
				<div className="modal-card">
					<header className="modal-card-head">
						<p className="modal-card-title">Cash Receipt Details</p>
						<button className="delete" aria-label="close" onClick={() => this.props.closeMe()}></button>
					</header>
					<section className="modal-card-body">
						<table className="table is-fullwidth">
							<thead>
								<tr>
									<th>ID</th>
									<th>Datetime</th>
									<th>Notes</th>
									<th>Total</th>
								</tr>
							</thead>
							<tbody>
								{
									this.state.receipts.map(r => {
										return (
											<tr key={r.ID}>
												<td>{r.ID}</td>
												<td>{r.datetime_receipt}</td>
												<td>{r.notes}</td>
												<td>$ {numbro(r.total).format({thousandSeparated: true, mantissa: 2})}</td>
											</tr>
										)
									})
								}
							</tbody>
							<tfoot>
								<tr>
									<td colSpan={2}></td>
									<td><strong>Total</strong></td>
									<td>$ {numbro(this.getTotal()).format({thousandSeparated: true, mantissa: 2})}</td>
								</tr>
							</tfoot>
						</table>
					</section>
					<footer className="modal-card-foot">
						<button className="button" onClick={() => this.props.closeMe()}>Close</button>
					</footer>
				</div>
			</div>
		)
	};

	getTotal() {
		let total = 0;

		this.state.receipts.forEach(r => { total += parseFloat(r.total) })

		return total;
	}
}