import React from "react";
import Navbar from "./components/navbar.js";
import Products from './views/products.js';
import Main from "./views/main.js";
import Markets from "./views/markets.js";
import MarketLogs from "./views/marketlogs.js";
import Transactions from "./views/transactions.js";
import CashReceipts from "./views/cashreceipts.js";
import Accounts from "./views/accounts.js";
import Pets from "./views/pets.js";
import Subscriptions from "./views/subscriptions.js";
import Categories from "./views/categories.js";
import Admin from "./views/admin.js";
import 'bulma/css/bulma.min.css';
import ApiSrv from "./ApiSrv.js";

import ColombiaReceipts from "./views/colombiareceipts.js";
import ColombiaAdmin from "./views/colombiaadmin.js";

const Api = new ApiSrv();

export default class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
			view: "main",
			tkn: "",
			loggedin: false,
			tt: undefined,
			colombiaReceipts: false
		};
	};

	componentDidMount() {
		if (window.localStorage.getItem("tkn")) {
			let tkn = window.localStorage.getItem("tkn");

			Api.getMethod("one_time_tokens", "validate_token", {tkn})
				.then(data => {
					if (data.Status == 1) {
						this.setState({ tkn, loggedin: true });
					}
				})
				.catch(ex => alert(ex));

			// fetch(`https://ppp.sgserch.com/api/one_time_tokens.php/validate_token?tkn=${tkn}`)
			// .then(resp => resp.json())
			// .then(data => {
			// 	if (data.Status == 1) {
			// 		this.setState({ tkn, loggedin: true });
			// 	}
			// })
			// .catch(ex => alert(ex));
		}
	}

	render() {
		if (this.state.loggedin && !this.state.colombiaReceipts)
			return (
				<section className="hero">
					<Navbar viewChanged={(view) => this.setState({view})} />

					<section className="section">
						{
							(this.state.view == "main" && <Main />)

							||

							(this.state.view == "markets" && <Markets />)

							||

							(this.state.view == "products" && <Products />)

							||

							(this.state.view == "marketlogs" && <MarketLogs />)

							||

							(this.state.view == "transactions" && <Transactions />)

							||

							(this.state.view == "cashreceipts" && <CashReceipts />)

							||

							(this.state.view == "accounts" && <Accounts />)

							||

							(this.state.view == "pets" && <Pets />)

							||

							(this.state.view == "subscriptions" && <Subscriptions />)

							||

							(this.state.view == "categories" && <Categories />)

							||

							(this.state.view == "admin" && <Admin />)

							||

							(this.state.view == "colombia" && <ColombiaAdmin />)
						}
					</section>
				</section>
			)
		else if (this.state.colombiaReceipts) {
			return (
				<ColombiaReceipts goBack={() => this.setState({ colombiaReceipts: false })} />
			)
		}
		else
			return (
				<section className="hero is-fullheight" style={{alignItems: "center", justifyContent: "center"}}>
					<div className="box" style={{width: "50%", height: 200}}>
						{this.state.tt && <div className="field">
							<figure className="image is-96x96">
								<img src={this.state.tt.qr} />
							</figure>
						</div>}
						<div className="field">
							<button className="button is-success" onClick={() => this.generateNewCode()}>Generate New Code</button>
						</div>
						<div className="field">
							<button className="button is-info" onClick={() => this.colombiaReceipts()}>Recibos Colombia 🇨🇴</button>
						</div>
					</div>
				</section>
			)
	};

	generateNewCode() {
		// fetch(`https://ppp.sgserch.com/api/one_time_tokens.php/create_new_token`)
		// 	.then(resp => resp.json())
		// 	.then(data => {
		// 		if (data.Status == 1) {
		// 			this.setState({tt: data.Data});
		// 			setTimeout(() => this.verifyIfActivated(), 1000);
		// 		}
		// 		else {
		// 			alert(data.ErrMsg);
		// 		}
		// 	})
		// 	.catch(ex => alert(ex));

		Api.postMethod("one_time_tokens", "create_new_token")
			.then(data => {
				if (data.Status == 1) {
					this.setState({tt: data.Data});
					setTimeout(() => this.verifyIfActivated(), 1000);
				}
				else {
					alert(data.ErrMsg);
				}
			})
			.catch(ex => alert(ex));
	};

	colombiaReceipts() {
		this.setState({ colombiaReceipts: true });
	};

	verifyIfActivated() {
		// fetch(`https://ppp.sgserch.com/api/one_time_tokens.php/is_activated?ID=${this.state.tt.ID}`)
		// 	.then(resp => resp.json())
		// 	.then(data => {
		// 		if (data.Status == 1) {
		// 			if (data.Data.OK == 1) {
		// 				window.localStorage.setItem("tkn", this.state.tt.tkn);
		// 				this.setState({ loggedin: true });
		// 			}
		// 			else {
		// 				setTimeout(() => this.verifyIfActivated(), 1000);
		// 			}
		// 		}
		// 		else {
		// 			alert(data.ErrMsg);
		// 		}
		// 	})
		// 	.catch(ex => alert(ex));

		Api.getMethod("one_time_tokens", "is_activated", {ID: this.state.tt.ID})
			.then(data => {
				if (data.Status == 1) {
					if (data.Data.OK == 1) {
						window.localStorage.setItem("tkn", this.state.tt.tkn);
						this.setState({ loggedin: true });
					}
					else {
						setTimeout(() => this.verifyIfActivated(), 1000);
					}
				}
				else {
					alert(data.ErrMsg);
				}
			})
			.catch(ex => alert(ex));
	}

	doLogin() {
		// fetch(`https://ppp.sgserch.com/api/one_time_tokens.php/validate_token?tkn=${this.state.tkn}`)
		// 	.then(resp => resp.json())
		// 	.then(data => {
		// 		if (data.Status == 1) {
		// 			window.localStorage.setItem("tkn", data.Data.tkn);
		// 			this.setState({ loggedin: true });
		// 		}
		// 		else {
		// 			alert(data.ErrMsg);
		// 		}
		// 	})
		// 	.catch(ex => alert(ex));

		Api.getMethod("one_time_tokens", "validate_token", {tkn: this.state.tkn})
			.then(data => {
				if (data.Status == 1) {
					window.localStorage.setItem("tkn", data.Data.tkn);
					this.setState({ loggedin: true });
				}
				else {
					alert(data.ErrMsg);
				}
			})
			.catch(ex => alert(ex));
	};
}