import React from "react";
import ApiSrv from "../ApiSrv";

const Api = new ApiSrv();

export default class Admin extends React.Component {
    render() {
        return (
            <section className="hero">

            </section>
        )
    }
};