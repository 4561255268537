import React from "react";
import logo from "../logo.png";

export default class Navbar extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			view: "main",
			items: [
				{ view: "accounts", icon_name: "folder-open-outline", name: "Accounts", active: true },
				{ view: "categories", icon_name: "file-tray-full-outline", name: "Categories", active: true },
				{ view: "transactions", icon_name: "cash-outline", name: "Transactions", active: true },
				{ view: "cashreceipts", icon_name: "receipt-outline", name: "Cash Receipts", active: true },
				{ view: "markets", icon_name: "storefront-outline", name: "Markets", active: false },
				{ view: "products", icon_name: "cart-outline", name: "Products", active: false },
				{ view: "subscriptions", icon_name: "calendar-outline", name: "Subscriptions", active: false },
				{ view: "pets", icon_name: "paw-outline", name: "Pets", active: false },
				{ view: "admin", icon_name: "lock-closed-outline", name: "Admin", active: false },
				{ view: "books", icon_name: "book-outline", name: "Books", active: false },
				{ view: "colombia", icon_name: "newspaper-outline", name: "Colombia Receipts", active: true }
			]
		}
	}

	render() {
		return (
			<nav className="navbar" role="navigation" aria-label="main navigation" style={{"boxShadow": "rgba(0, 0, 0, 0.45) 0px 25px 20px -20px"}}>
				<div className="navbar-brand">
					<a className="navbar-item" onClick={() => this.changeView("main")}>
						<img src={logo} />
						<strong>ExpControl</strong>
					</a>

					<a role="button" className="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
						<span aria-hidden="true"></span>
						<span aria-hidden="true"></span>
						<span aria-hidden="true"></span>
					</a>
				</div>

				<div id="navbarBasicExample" className="navbar-menu">
					<div className="navbar-start">
						{
							this.state.items.map((it) => {
								return (
									it.active && <a className={"navbar-item " + (this.state.view == it.view ? "has-background-primary-dark has-text-white" : "") } onClick={() => this.changeView(it.view)}>
										<ion-icon name={it.icon_name} style={{"marginRight": 10}}></ion-icon> {it.name}
									</a>
								)
							})
						}
					</div>

				</div>
			</nav>
		)
	};
	
	changeView(view) {
		this.setState({view});
		this.props.viewChanged(view);
	}
}