import React from "react";

export default class ColombiaAdmin extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected_type: "",
            start_date: "",
            end_date: ""
        }

    }

    render() {
        return (
            <section className="hero">
                <div className="container">
                    <div className="columns">
                        <div className="column">
                            <label className="label">Type</label>
                        </div>

                        <div className="column">
                            <div className="select">
                                <select value={this.state.selected_type}>
                                    <option value="">(Select a type)</option>
                                    <option value="LUZ">Luz</option>
                                    <option value="AGUA">Agua y Alcantarillado</option>
                                    <option value="GAS">Gas</option>
                                    <option value="ADMIN">Administración</option>
                                </select>
                            </div>
                        </div>

                        <div className="column">
                            <label className="label">Start Date</label>
                        </div>

                        <div className="column">
                            <input className="input" type="date" value={this.state.start_date} onChange={(ev) => this.setState({start_date: ev.target.value})} />
                        </div>

                        <div className="column">
                            <label className="label">End Date</label>
                        </div>
                        
                        <div className="column">
                            <input className="input" type="date" value={this.state.end_date} onChange={(ev) => this.setState({end_date: ev.target.value})} />
                        </div>

                        <div className="column">
							<button className="button is-primary">
								<ion-icon name="search"></ion-icon>
							</button>
						</div>
                    </div>
                </div>
            </section>
        )
    }
};