import React from "react";
import ApiSrv from "../ApiSrv";
import numbro from "numbro";

const Api = new ApiSrv();
numbro.setLanguage("en-US");

export default class Products extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			search: "",
			products: [],
			markets: [],

			keepModalOpen: false,
			index: -1,
			productID: 0,
			productCode: "",
			productName: "",

			newLogModal: false,
			selectedProductID: 0,
			selectedProductCode: "",
			selectedProductName: "",
			selectedMarketID: 0,
			selectedDate: "",
			selectedPrice: 0,

			productLogsModal: false,
			selectedProductLogs: []
		};
	};

	componentDidMount() {
		Api.getMethod("market", "get_list")
			.then(data => {
				if (data.Status == 1) {
					this.setState({markets: data.Data});
				}
				else {
					alert(data.ErrMsg);
				}
			})
			.catch(ex => alert(ex));
	};

	render() {
		return (
			<section className="hero">
				<div className={"modal" + (this.state.showModal ? " is-active" : "")}>
					<div className="modal-background"></div>
					<div className="modal-card">
						<header className="modal-card-head">
							<p className="modal-card-title">Edit</p>
							<button className="delete" aria-label="close" onClick={() => this.setState({showModal: false})}></button>
						</header>
						<section className="modal-card-body">
							<input className="input" type="text" value={this.state.productCode} onChange={(ev) => this.setState({productCode: ev.target.value})} placeholder="Code"  />
							<input className="input" type="text" value={this.state.productName} onChange={(ev) => this.setState({productName: ev.target.value})} placeholder="Name"  />
							<input  type="checkbox" value={this.state.keepModalOpen} onChange={(ev) => this.setState({keepModalOpen: ev.target.value})} /> Keep window open
						</section>
						<footer className="modal-card-foot">
							<button className="button is-success" onClick={() => this.save()}>Save</button>
							<button className="button" onClick={() => this.setState({showModal: false})}>Cancel</button>
						</footer>
					</div>
				</div>

				<div className={"modal" + (this.state.newLogModal ? " is-active" : "")}>
					<div className="modal-background"></div>
					<div className="modal-card">
						<header className="modal-card-head">
							<p className="modal-card-title">({this.state.selectedProductCode} - {this.state.selectedProductName})</p>
							<button className="delete" aria-label="close" onClick={() => this.setState({newLogModal: false})}></button>
						</header>
						<section className="modal-card-body">
							<div className="select">
								<select value={this.state.selectedMarketID} onChange={(ev) => this.setState({selectedMarketID: ev.target.value})}>
									<option value={0}>(Select a market)</option>
									{
										this.state.markets.map(mk => {
											return (
												<option key={mk.ID} value={mk.ID}>{mk.market_name}</option>
											)
										})
									}
								</select>
							</div>
							<input className="input" type="date" value={this.state.selectedDate} onChange={(ev) => this.setState({selectedDate: ev.target.value})}  />
							<input className="input" type="number" value={this.state.selectedPrice} onChange={(ev) => this.setState({selectedPrice: ev.target.value})}  />
						</section>
						<footer className="modal-card-foot">
							<button className="button is-success" onClick={() => this.saveLog()}>Save</button>
							<button className="button" onClick={() => this.setState({newLogModal: false})}>Cancel</button>
						</footer>
					</div>
				</div>

				<div className={"modal" + (this.state.productLogsModal ? " is-active" : "")}>
					<div className="modal-background"></div>
					<div className="modal-card">
						<header className="modal-card-head">
							<p className="modal-card-title">Last 5 logs</p>
							<button className="delete" aria-label="close" onClick={() => this.setState({productLogsModal: false})}></button>
						</header>
						<section className="modal-card-body">
							<table className="table is-fullwidth">
								<thead>
									<tr>
										<th>Date When</th>
										<th>Market Name</th>
										<th>Price</th>
									</tr>
								</thead>
								<tbody>
									{
										this.state.selectedProductLogs.map(pl => {
											return (
												<tr>
													<td>{pl.date_when}</td>
													<td>{pl.market_name}</td>
													<td>$ {numbro(pl.price).format({thousandSeparated: true, mantissa: 2})}</td>
												</tr>
											)
										})
									}
								</tbody>
							</table>
						</section>
						<footer className="modal-card-foot">							
							<button className="button" onClick={() => this.setState({productLogsModal: false})}>Cancel</button>
						</footer>
					</div>
				</div>

				<div className="columns">
					<div className="column is-four-fifths">
						<div className="field">
							<input className="input" type="text" placeholder="Search" value={this.state.search} onChange={(ev) => this.setState({search: ev.target.value})} />
						</div>
					</div>
					<div className="column">
						<button className="button is-primary" onClick={() => this.searchProducts()}>Search</button>
					</div>
				</div>
				<table className="table">
					<thead>
						<tr>
							<th></th>
							<th>Code</th>
							<th>Name</th>
							<th>Last Time Bought</th>
							<th>Last Market</th>
							<th>Last Price</th>
							<th>Trend</th>
							<th>
								<button className="button is-primary" onClick={() => this.setState({showModal: true, index: -1, productID: 0, productCode: "", productName: ""})}>Add</button>
							</th>
						</tr>
					</thead>
					<tbody>
						{
							this.state.products.map((pr, index) => {
								return (
									<tr key={pr.ID}>
										<td>
											<figure className="image is-64x64">
												<img src={`https://ppp.sgserch.com/p/${pr.product_code}.jpg`} />
											</figure>
										</td>
										<td>{pr.product_code}</td>
										<td>{pr.product_name}</td>
										<td>{pr.last_log?.last_5[0]?.date_when}</td>
										<td>{pr.last_log?.last_5[0]?.market_name}</td>
										<td>$ {numbro(pr.last_log?.last_5[0]?.price).format({thousandSeparated: true, mantissa: 2})}</td>
										<td>
											<button className="button is-white" onClick={() => this.setState({productLogsModal: true, selectedProductLogs: pr.last_log.last_5})}>
												{
													(pr.last_log?.up_or_down == 1 &&
													<span className="has-text-danger"><ion-icon name="trending-up-outline"></ion-icon></span>)

													||

													(pr.last_log?.up_or_down == -1 &&
													<span className="has-text-success"><ion-icon className="has-text-danger" name="trending-down-outline"></ion-icon></span>)

													||

													(pr.last_log?.up_or_down == 0 &&
													<span className="has-text-dark"><ion-icon className="has-text-dark" name="remove-outline"></ion-icon></span>)
												}
												
											</button>
										</td>
										<td>
											<button className="button is-info" onClick={() => this.edit(index)}>Edit</button>
											<button className="button is-success" onClick={() => this.addLog(index, pr.ID, pr.product_code, pr.product_name)}>Add Log</button>
										</td>
									</tr>
								)
							})
						}
					</tbody>
				</table>
			</section>
		);
	};

	searchProducts() {
		Api.getMethod("products", "search_products", { search_param: this.state.search })
			.then(data => {
				if (data.Status == 1) {
					this.setState({products: data.Data});
				}
				else {
					alert(data.ErrMsg);
				}
			})
			.catch(ex => alert(ex));
	}

	edit(index) {
		let cop = [...this.state.products];
		let item = cop[index];

		this.setState({
			index,
			productID: item.ID,
			productCode: item.product_code,
			productName: item.product_name,
			showModal: true
		});
	};

	addLog(index, productID, productCode, productName) {
		this.setState({
			index,
			selectedMarketID: 0,
			selectedProductID: productID,
			selectedProductCode: productCode,
			selectedProductName: productName,
			newLogModal: true
		});
	};

	save() {
		let p = {
			product_code: this.state.productCode,
			product_name: this.state.productName
		};

		if (this.state.productID > 0)
			p.ID = this.state.productID;

		Api.postMethod("products", (this.state.productID == 0 ? "insert" : "update") + "_product", p)
			.then(data => {
				if (data.Status == 1) {
					let cop = [...this.state.products];

					if (this.state.productID == 0) {
						cop.push(data.Data);
					}
					else {
						cop[this.state.index].ID = data.Data.ID;
						cop[this.state.index].product_code = data.Data.product_code;
						cop[this.state.index].product_name = data.Data.product_name;
					}

					this.setState({ products: cop, showModal: this.state.keepModalOpen ? true : false, productID: 0, productCode: "", productName: "", index: -1 });
				}
				else {
					alert(data.ErrMsg);
				}
			})
			.catch(ex => alert(ex));
	};

	saveLog() {
		let p = {
			market_ID: this.state.selectedMarketID,
			product_ID: this.state.selectedProductID,
			date_when: this.state.selectedDate,
			price: this.state.selectedPrice
		};

		Api.postMethod("market", "insert_market_log", p)
			.then(data => {
				if (data.Status == 1) {
					let cop = [...this.state.products];
					cop[this.state.index].last_log = data.Data;

					this.setState({
						products: cop,
						index: -1,
						selectedDate: "",
						selectedMarketID: 0,
						selectedPrice: 0,
						selectedProductCode: "",
						selectedProductID: 0,
						selectedProductName: "",
						newLogModal: false
					});
				}
				else {
					alert(data.ErrMsg);
				}
			})
			.catch(ex => alert(ex));
	};
}