import React from "react";
import ApiSrv from "../ApiSrv";

const Api = new ApiSrv();

export default class ColombiaReceipts extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            type: "",
            dateReceived: "",
            dateDue: "",
            total: 0,
            fileName: "",
            selectedFile: null,

            saving: false,
            fileReady: false
        }
    }

    render() {
        return (
            !this.state.saving && !this.state.fileReady &&
            <section className="hero is-fullheight" style={{alignItems: "center", justifyContent: "center"}}>
                <div className="box" style={{width: "50%"}}>
                    <div className="field">
                        <label className="label">Tipo</label>
                        <div className="control">
                            <div className="select">
                                <select value={this.state.type} onChange={(ev) => this.setState({type: ev.target.value})}>
                                    <option value="">(Seleccione una opción)</option>
                                    <option value="LUZ">Luz</option>
                                    <option value="AGUA">Agua y Alcantarillado</option>
                                    <option value="GAS">Gas</option>
                                    <option value="ADMIN">Administración</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">Fecha de recepción</label>
                        <div className="control">
                            <input className="input" type="date" value={this.state.dateReceived} onChange={(ev) => this.setState({dateReceived: ev.target.value})} />
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">Fecha límite de pago</label>
                        <div className="control">
                            <input className="input" type="date" value={this.state.dateDue} onChange={(ev) => this.setState({dateDue: ev.target.value})} />
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">Total</label>
                        <div className="control">
                            <input className="input" type="number" value={this.state.total} onChange={(ev) => this.setState({total: ev.target.value})} />
                        </div>
                    </div>
                    <div className="field">
                        <div className="file has-name is-fullwidth">
                            <label className="file-label">
                                <input className="file-input" type="file" onChange={(event) => this.onFileChange(event) } />
                                <span className="file-cta">
                                    <span className="file-icon">
                                        <ion-icon name="cloud-upload-outline"></ion-icon>
                                    </span>
                                    <span className="file-label">
                                        Subir Recibo
                                    </span>
                                </span>
                                <span className="file-name">
                                    {this.state.fileName}
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className="container is-widescreen is-justify-content-space-between">
                        <button className="button is-success" onClick={() => this.saveReceipt()}>Guardar Recibo</button>
                        <button className="button is-info" onClick={() => this.props.goBack()}>Regresar</button>
                    </div>
                </div>
            </section>

            ||

            this.state.saving && !this.state.fileReady &&

            <section className="hero is-fullheight" style={{alignItems: "center", justifyContent: "center"}}>
                <strong><h1 className="title">ESTAMOS GUARDANDO EL ARCHIVO. NO CIERRE NI REFRESQUE ESTA PÁGINA!</h1></strong>
                <br />
                <strong><h1 className="subtitle">ESPERE POR FAVOR...</h1></strong>
            </section>

            ||

            <section className="hero is-fullheight is-success" style={{alignItems: "center", justifyContent: "center"}}>
                <strong><h1 className="title">LA CARGA TERMINÓ CORRECTAMENTE!</h1></strong>

                <br />

                <button className="button is-info" onClick={() => this.setState({ fileReady: false })}>OK</button>
            </section>
        )
    }

    onFileChange(event) {
        // Update the state
        this.setState({ selectedFile: event.target.files[0], fileName: event.target.files[0].name });
    };

    convertToB64() {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(this.state.selectedFile);
        
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    saveReceipt() {
        var p = {
            type: this.state.type,
            date_received: this.state.dateReceived,
            due_date: this.state.dateDue,
            total: this.state.total
        };

        var msg = "";
        
        if (p.type == "") {
            msg += "- Seleccione el tipo de recibo\n";
        }

        if (p.date_received == "") {
            msg += "- Seleccione la Fecha de Recepción\n";
        }

        if (p.due_date == "") {
            msg += "- Seleccione la Fecha límite de pago\n";
        }

        if (p.total == 0) {
            msg += "- El total debe ser mayor a 0\n";
        }

        // if (!this.state.file) {
        //     msg += "- La carga del archivo es obligatoria\n";
        // }

        if (msg != "") {
            alert(msg);
            return;
        }

        this.setState({ saving: true });

        Api.postMethod("colombia_receipts", "insert_receipt", p)
            .then(data => {
                if (data.Status == 1) {
                    this.uploadTheFile(data);
                }
                else {
                    alert("Ocurrió unn error!");
                    this.setState({ saving: false });
                }
            })
            .catch(ex => { alert("Ocurrió unn error!"); this.setState({ saving: false }); });
    };

    uploadTheFile(data) {
        this.convertToB64()
            .then((file) => {
                var p = { file, file_name: this.state.fileName, receipt_ID: data.Data.ID };

                Api.postMethod("colombia_receipts", "upload_file", p)
                    .then(fData => {
                        if (fData.Status == 1) {
                            this.setState({ saving: false, type: "", dateReceived: "", dateDue: "", total: 0, file: null, fileName: "", fileReady: true });
                        }
                        else {
                            alert("Ocurrió unn error!"); this.setState({ saving: false });
                        }
                    })
                    .catch(ex => console.log(ex)); 
                }
            );
    }
}