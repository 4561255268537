import React from "react";
import EditModal from "../components/accounts/editmodal";
import ApiSrv from "../ApiSrv";

const Api = new ApiSrv();

export default class Accounts extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			accounts: [],
			showEditModal: false,
			selected_account: undefined
		};
	};

	componentDidMount() {
		Api.getMethod("accounts", "get_list", { show_all: 1 })
			.then(data => {
				if (data.Status == 1) {
					this.setState({accounts: data.Data});
				}
				else {
					alert(data.ErrMsg);
				}
			})
			.catch(ex => alert(ex));
	}

	render() {
		return (
			<section className="hero">
				{
					this.state.showEditModal &&
					<EditModal	data={this.state.selected_account}
								accountsList={this.state.accounts}
								closeMe={(result, index) => this.endEdit(result, index)} />
				}
				<table className="table">
					<thead>
						<tr>
							<th>ID</th>
							<th>Image</th>
							<th>Name</th>
							<th>Cut Off Day</th>
							<th>Due Day</th>
							<th>Paying Account</th>
							<th>Active</th>
							<th>
								<button className="button is-primary" onClick={() => this.setState({showEditModal: true})}>Add</button>
							</th>
						</tr>
					</thead>
					<tbody>
						{
							this.state.accounts.map((acc, index) => {
								return (
									<tr key={acc.ID}>
										<td>{acc.ID}</td>
										<td>
											{
												acc.image_name &&
												<figure className="image is-64x64">
													<img src={`https://ppp.sgserch.com/p/${acc.image_name}`} />
												</figure>
											}
										</td>
										<td>{acc.account_name}</td>
										<td>{acc.cut_off_day}</td>
										<td>{acc.due_day}</td>
										<td>{acc.paying_account_ID && this.state.accounts.find(a => { return a.ID == acc.paying_account_ID }).account_name}</td>
										<td>{acc.active == 1 ? "Yes" : "No"}</td>
										<td>
											<button className="button is-info" onClick={() => this.setState({selected_account: {account: acc, index}, showEditModal: true})}>Edit</button>
										</td>
									</tr>
								)
							})
						}
					</tbody>
				</table>
			</section>
		)
	};

	endEdit(result, index) {
		let cop = [...this.state.accounts];
		
		if (result) {
			if (index) {
				cop[index].account_name = result.account_name;
				cop[index].cut_off_day = result.cut_off_day;
				cop[index].due_day = result.due_day;
				cop[index].paying_account_ID = result.paying_account_ID;
				cop[index].image_name = result.image_name;
				cop[index].active = result.active;
			}
			else {
				cop.push({
					ID: result.ID,
					account_name: result.account_name,
					cut_off_day: result.cut_off_day,
					due_day: result.due_day,
					paying_account_ID: result.paying_account_ID,
					image_name: result.image_name,
					active: result.active
				});
			}
		}

		this.setState({ showEditModal: false, selected_account: undefined, accounts: cop });
	};
};