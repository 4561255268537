import React from "react";
import ApiSrv from "../ApiSrv";
import AccountsSummaryCard from "../components/accountssummarycard";
import numbro from "numbro";

const Api = new ApiSrv();

export default class Main extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			summary: []
		};
	}

	componentDidMount() {
		this.getAccountsSummary();
	}

	render() {
		return (
			<section className="hero">
				{
					this.state.summary.map(sum => {
						return (
							<AccountsSummaryCard key={sum.ID} account={sum} accountWasPayed={() => this.getAccountsSummary()} />
						)
					})
				}
			</section>
		)
	};

	getAccountsSummary() {
		Api.getMethod("accounts", "get_current_cutoff_summary")
			.then(data => {
				if (data.Status == 1) {
					this.setState({summary: data.Data});
				}
				else {
					alert(data.ErrMsg);
				}
			})
			.catch(ex => alert(ex));
	};
};