import React from "react";
import ApiSrv from "../../ApiSrv";

const Api = new ApiSrv();

export default class EditModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			subs: [],

			ID: 0,
			sub_name: "",
            account_ID: 0,
            category: "",
            active: false,
            is_monthly: false,
            is_yearly: false,
            day_when: 0,
            month_when: 0
		};
	}

	componentDidMount() {
		this.setState({subs: this.props.subsList});

		if (this.props.data) {
			this.setState({
				ID: this.props.data.account.ID,
				name: this.props.data.account.account_name,
				cut_off_day: this.props.data.account.cut_off_day ? this.props.data.account.cut_off_day : 0,
				due_day: this.props.data.account.due_day ? this.props.data.account.due_day : 0,
				paying_account_ID: this.props.data.account.paying_account_ID ? this.props.data.account.paying_account_ID : 0,
				image_name: this.props.data.account.image_name ? this.props.data.account.image_name : "",
				active: this.props.data.account.active == 1
			});
		}

		// Api.getMethod("accounts", "get_list")
		// 	.then(data => {
		// 		if (data.Status == 1) {
		// 			this.setState({accounts: data.Data});
		// 		}
		// 		else {
		// 			alert(data.ErrMsg);
		// 		}
		// 	})	
		// 	.catch(ex => alert(ex));
	}

	render() {
		return (
			<div className="modal is-active">
				<div className="modal-background"></div>
				<div className="modal-card">
					<header className="modal-card-head">
						<p className="modal-card-title">{this.state.ID == 0 ? "Add" : "Edit"} Subscription</p>
						<button className="delete" aria-label="close" onClick={() => this.props.closeMe()}></button>
					</header>
					<section className="modal-card-body">
						<div className="columns">
							<div className="column">
								<input className="input" placeholder="Subscription Name" type="text" value={this.state.sub_name} onChange={(ev) => this.setState({sub_name: ev.target.value})} />
							</div>							
						</div>

						<div className="columns">
							<div className="column">
								<input className="input" placeholder="Cut Off Day" type="number" value={this.state.cut_off_day} onChange={(ev) => this.setState({cut_off_day: ev.target.value})} />
							</div>
							<div className="column">
								<input className="input" placeholder="Due Day" type="number" value={this.state.due_day} onChange={(ev) => this.setState({due_day: ev.target.value})} />
							</div>
						</div>

						<div className="columns">
							<div className="column">
								<div className="select">
									<select value={this.state.paying_account_ID} onChange={(ev) => this.setState({paying_account_ID: ev.target.value})}>
										<option value={0}></option>
										{
											this.state.accounts.map(a => {
												return (
													<option key={a.ID} value={a.ID}>{a.account_name}</option>
												)
											})
										}
									</select>
								</div>
							</div>
						</div>

						<div className="columns">
							<div className="column">
								<input className="input" type="text" placeholder="Image Name" value={this.state.image_name} onChange={(ev) => this.setState({image_name: ev.target.value})} />
							</div>
						</div>

						<div className="columns">
							<div className="column is-2">
								<label className="label">Active</label>
							</div>
							<div className="column">
								<input type="checkbox" checked={this.state.active} onChange={(ev) => this.setState({active: ev.target.checked})} />
							</div>
						</div>
						
					</section>
					<footer className="modal-card-foot">
						<button className="button is-success" onClick={() => this.save()}>Save</button>
						<button className="button" onClick={() => this.props.closeMe()}>Close</button>
					</footer>
				</div>
			</div>
		)
	};

	save() {
		let val = true;
		let msg = "";

		if (this.state.name.trim() == "") {
			msg += "- Account name is empty\r";
			val = false;
		}

		if (!val)
			return;

		let p = {
			account_name: this.state.name.trim(),
			active: this.state.active ? 1 : 0
		};

		if (this.state.ID != 0)
			p.ID = this.state.ID;

		if (this.state.cut_off_day != 0)
			p.cut_off_day = this.state.cut_off_day;

		if (this.state.due_day != 0)
			p.due_day = this.state.due_day;

		if (this.state.paying_account_ID != 0)
			p.paying_account_ID = this.state.paying_account_ID;

		if (this.state.image_name.trim() != "")
			p.image_name = this.state.image_name.trim();

		Api.postMethod("accounts", (this.state.ID == 0 ? "insert" : "update") + "_account", p)
			.then(data => {
				if (data.Status == 1) {
					this.props.closeMe(data.Data, this.props.data?.index ? this.props.data?.index : undefined);
				}
				else {
					alert(data.ErrMsg);
				}
			})
			.catch(ex => alert(ex));
	};
};