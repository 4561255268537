import React from "react";
import ApiSrv from "../ApiSrv.js"
import numbro from "numbro";
import CashReceiptDetail from "../components/cashreceiptdetail.js";

const Api = new ApiSrv();
numbro.setLanguage("en-US");

export default class Transactions extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			accounts: [],
			categories: [],

			account_ID: 0,
			category_code: "",
			start_date: "",
			end_date: "",
			total_expensed: 0,
			total_payed: 0,
			total_due: 0,

			transactions: [],

			showModal: false,
			add_ID: 0,
			add_account_ID: 0,
			add_category: "",
			add_type: "EXP",
			add_datetime: "",
			add_amount: 0,
			add_usd: 0,
			add_notes: "",

			selected_transaction_ID: undefined
		};
	};

	componentDidMount() {
		Api.getMethod("accounts", "get_list")
			.then(data => {
				if (data.Status == 1) {
					this.setState({ accounts: data.Data });
				}
				else {
					alert(data.ErrMsg);
				}
			})
			.catch(ex => alert(ex));

		Api.getMethod("categories", "get_list")
			.then(data => {
				if (data.Status == 1) {
					this.setState({ categories: data.Data });
				}
				else {
					alert(data.ErrMsg);
				}
			})
			.catch(ex => alert(ex));
	};

	render() {
		return (
			<section className="hero">
				<div className={"modal" + (this.state.showModal ? " is-active" : "")}>
					<div className="modal-background"></div>
					<div className="modal-card">
						<header className="modal-card-head">
							<p className="modal-card-title">{this.state.add_ID == 0 ? "Add" : "Edit"} Transaction {this.state.add_ID != 0 ? `# ${this.state.add_ID}` : ""}</p>
							<button className="delete" aria-label="close" onClick={() => this.setState({showModal: false})}></button>
						</header>
						<section className="modal-card-body">
							<div className="field">
								<div className="columns">
									<div className="column">
										<label className="label">Account</label>
									</div>
									<div className="column">
										<div className="select">
											<select value={this.state.add_account_ID} onChange={(ev) => this.setState({add_account_ID: ev.target.value})}>
												<option value={0}></option>
												{
													this.state.accounts.map(acc => {
														return (
															<option key={acc.ID} value={acc.ID}>{acc.account_name}</option>
														)
													})
												}
											</select>
										</div>
									</div>
								</div>

								<div className="columns">
									<div className="column">
										<label className="label">Category</label>
									</div>
									<div className="column">
										<div className="select">
											<select value={this.state.add_category} onChange={(ev) => this.setState({add_category: ev.target.value})}>
												<option value=""></option>
												{
													this.state.categories.map(cat => {
														return (
															<option key={cat.cat_code} value={cat.cat_code}>{cat.cat_name}</option>
														)
													})
												}
											</select>
										</div>
									</div>
								</div>

								<div className="columns">
									<div className="column">
										<label className="label">Type</label>
									</div>
									<div className="column">
										<div className="select">
											<select value={this.state.add_type} onChange={(ev) => this.setState({add_type: ev.target.value})}>
												<option value="EXP">Expense</option>
												<option value="PAY">Payed</option>
											</select>
										</div>
									</div>
								</div>

								<div className="columns">
									<div className="column">
										<label className="label">Datetime</label>
									</div>
									<div className="column">
										<input className="input" type="datetime-local" value={this.state.add_datetime} onChange={(ev) => this.setState({add_datetime: ev.target.value})} />
									</div>
								</div>

								<div className="columns">
									<div className="column">
										<label className="label">Amount</label>
									</div>
									<div className="column">
										<input className="input" type="number" value={this.state.add_amount} onChange={(ev) => this.setState({add_amount: ev.target.value})} />
									</div>
								</div>

								<div className="columns">
									<div className="column">
										<label className="label">Amount USD</label>
									</div>
									<div className="column">
										<input className="input" type="number" value={this.state.add_usd} onChange={(ev) => this.setState({add_usd: ev.target.value})} />
									</div>
								</div>

								<div className="columns">
									<div className="column">
										<label className="label">Notes</label>
									</div>
									<div className="column">
										<input className="input" type="text" value={this.state.add_notes} onChange={(ev) => this.setState({add_notes: ev.target.value})} />
									</div>
								</div>
							</div>
						</section>
						<footer className="modal-card-foot">
							<button className="button is-success" onClick={() => this.save()}>Save</button>
							<button className="button" onClick={() => this.setState({showModal: false})}>Cancel</button>
						</footer>
					</div>
				</div>

				{
					this.state.selected_transaction_ID &&
					<CashReceiptDetail closeMe={() => this.setState({selected_transaction_ID: undefined})} transaction_ID={this.state.selected_transaction_ID} />
				}

				<div classname="container">
					<div className="columns">
						<div className="column">
							<label className="label">Account</label>
						</div>
						<div className="column">
							<div className="select">
								<select value={this.state.account_ID} onChange={(ev) => this.setState({account_ID: ev.target.value})}>
									<option value={0}>(Select an account)</option>
									{
										this.state.accounts.map(acc => {
											return (
												<option key={acc.ID} value={acc.ID}>{acc.account_name}</option>
											)
										})
									}
								</select>
							</div>
						</div>
						<div className="column">
							<label className="label">Category</label>
						</div>
						<div className="column">
							<div className="select">
								<select value={this.state.category_code} onChange={(ev) => this.setState({category_code: ev.target.value})}>
									<option value="">(Select a category)</option>
									{
										this.state.categories.map(cat => {
											return (
												<option key={cat.cat_code} value={cat.cat_code}>{cat.cat_name}</option>
											)
										})
									}
								</select>
							</div>
						</div>
						<div className="column">
							<label className="label">Start Date</label>
						</div>
						<div className="column">
							<input className="input" type="date" value={this.state.start_date} onChange={(ev) => this.setState({start_date: ev.target.value})} />
						</div>
						<div className="column">
							<label className="label">End Date</label>
						</div>
						<div className="column">
							<input className="input" type="date" value={this.state.end_date} onChange={(ev) => this.setState({end_date: ev.target.value})} />
						</div>
						<div className="column">
							<button className="button is-primary" onClick={() => this.search()}>
								<ion-icon name="search"></ion-icon>
							</button>
						</div>
						<div className="column">
							<button className="button is-info" onClick={() => this.addTransaction()}>
								<ion-icon name="add-outline"></ion-icon>
							</button>
						</div>
						<div className="column">
							<button className="button is-link" onClick={() => this.clear()}>
								<ion-icon name="brush-outline"></ion-icon>
							</button>
						</div>
					</div>
					<div className="columns">
						<div className="column">
							<label className="label">Total Expensed:</label>
						</div>
						<div className="column">
							<span>$ {numbro(this.state.total_expensed).format({thousandSeparated: true, mantissa: 2})}</span>
						</div>
						<div className="column">
							<label className="label">Total Payed:</label>
						</div>
						<div className="column">
							<span>$ {numbro(this.state.total_payed).format({thousandSeparated: true, mantissa: 2})}</span>
						</div>
						<div className="column">
							<label className="label">Total Due:</label>
						</div>
						<div className="column">
							<span>$ {numbro(this.state.total_due).format({thousandSeparated: true, mantissa: 2})}</span>
						</div>
					</div>
				</div>

				<table className="table">
					<thead>
						<tr>
							<th></th>
							<th>Account</th>
							<th>Category</th>
							<th>ID</th>
							<th>Date</th>
							<th>Amount</th>
							<th>Amount USD</th>
							<th>Notes</th>
							<th>Cash Receipts</th>
							<td></td>
							<td></td>
						</tr>
					</thead>
					<tbody>
						{
							this.state.transactions.map(tr => {
								return (
									<tr key={tr.ID} className={tr.tran_type == "PAY" ? "has-background-primary-dark has-text-white" : ""}>
										<td>
											<ion-icon name={tr.icon}></ion-icon>
										</td>
										<td>{tr.account_name}</td>
										<td>{tr.cat_name}</td>
										<td>{tr.ID}</td>
										<td>{tr.datetime_transaction}</td>
										<td>$ {numbro(tr.total).format({thousandSeparated: true, mantissa: 2})}</td>
										<td>$ {numbro(tr.total_USD).format({thousandSeparated: true, mantissa: 2})}</td>
										<td>{tr.notes}</td>
										<td>
											{<a onClick={() => this.setState({selected_transaction_ID: tr.ID})}>View Cash Receipts</a>}
										</td>
										<td>
											{!tr.cash_receipt_ID && <a onClick={() => this.edit(tr)}>Edit</a>}
										</td>
										<td>
											<a onClick={() => this.anull(tr.ID)}>Anull</a>
										</td>
									</tr>
								)
							})
						}
					</tbody>
				</table>
			</section>
		)
	};

	search() {
		let p = {};

		if (this.state.account_ID != 0)
			p.account_ID = this.state.account_ID;

		if (this.state.category_code != 0)
			p.category = this.state.category_code;

		if (this.state.start_date != "")
			p.start_date = this.state.start_date;

		if (this.state.end_date != 0)
			p.end_date = this.state.end_date;

		Api.getMethod("transactions", "search_transactions", p)
			.then(data => {
				if (data.Status == 1) {
					let exp = 0;
					let payed = 0;
					let due = 0;

					data.Data.forEach(tr => {
						if (tr.tran_type == "EXP") {
							exp += parseFloat(tr.total);
						}
						else {
							payed += parseFloat(tr.total);
						}
					});

					due = exp - payed;

					this.setState({transactions: data.Data, total_expensed: exp, total_payed: payed, total_due: due});
				}
				else {
					alert(data.ErrMsg);
				}
			})
			.catch(ex => { alert(ex) });

	};

	clear() {
		this.setState({
			transactions: [],
			account_ID: 0,
			category_code: "",
			start_date: "",
			end_date: "",
			total_expensed: 0,
			total_payed: 0,
			total_due: 0
		});
	};

	addTransaction() {
		this.setState({
			add_ID: 0,
			add_account_ID: 0,
			add_category: "",
			add_type: "EXP",
			add_datetime: "",
			add_amount: 0,
			add_usd: 0,
			add_notes: "",
			showModal: true
		});
	};

	save() {
		let p = {
			account_ID: this.state.add_account_ID,
			category: this.state.add_category,
			tran_type: this.state.add_type,
			total: this.state.add_amount,
			total_USD: this.state.add_usd,
			notes: this.state.add_notes
		};

		if (this.state.add_datetime != "") {
			p.datetime = this.state.add_datetime.replace("T", " ") + ":00";
		}

		if (this.state.add_ID != 0) {
			p.ID = this.state.add_ID;
		}

		Api.postMethod("transactions", this.state.add_ID == 0 ? "insert_transaction" : "update_transaction", p)
			.then(data => {
				if (data.Status == 1) {
					alert("Transaction saved correctly!");

					this.setState({
						add_ID: 0,
						add_account_ID: 0,
						add_category: "",
						add_type: "EXP",
						add_datetime: "",
						add_amount: 0,
						add_usd: 0,
						add_notes: "",
						showModal: false
					});
				}
				else {
					alert(data.ErrMsg);
				}
			})
			.catch(ex => alert(ex));
	};

	anull(ID) {
		Api.postMethod("transactions", "update_transaction", { ID, anulled: 1 })
			.then(data => {
				if (data.Status == 1) {
					alert("Transaction was anulled successfully!");
					this.search();
				}
				else {
					alert(data.ErrMsg);
				}
			})
			.catch(ex => alert(ex));
	};

	edit(transaction) {
		this.setState({
			showModal: true,
			add_ID: transaction.ID,
			add_account_ID: transaction.account_ID,
			add_category: transaction.category,
			add_type: transaction.tran_type,
			add_datetime: transaction.datetime_transaction,
			add_amount: transaction.total,
			add_usd: transaction.total_USD,
			add_notes: transaction.notes});
	}
};