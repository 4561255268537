import React from "react";
import numbro from "numbro";
import ApiSrv from "../ApiSrv";

numbro.setLanguage("en-US");
const Api = new ApiSrv();

export default class AccountsSummaryCard extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="card" style={{"marginTop": 50}}>
				<div className="card-content">
					<div className="columns">
						<div className="column is-1">
							<figure className="image is-64x64">
								<img src={`https://ppp.sgserch.com/p/${this.props.account.image_name}`} />
							</figure>
						</div>
						<div className="column">
							<p className="title">
								{this.props.account.account_name}
							</p>
						</div>
					</div>
					<div className="columns">
						<div className="column">
							<p className="subtitle">
								Total Due $ {numbro(this.props.account.total).format({thousandSeparated: true, mantissa: 2})}
							</p>
						</div>
						<div className="column">
							<p className="subtitle">
								Cut off day: {this.props.account.cut_off_day}
							</p>
						</div>
						<div className="column">
							<p className="subtitle">
								Due day: {this.props.account.due_day}
							</p>
						</div>
					</div>
				</div>
				<footer className="card-footer">
					<p className="card-footer-item">
						<span>
							<a href="#" onClick={() => this.markAsPayed()}>Mark as Payed</a>
						</span>
					</p>
				</footer>
			</div>
		)
	};

	markAsPayed() {
		Api.postMethod("accounts", "update_cutoff_payed", { account_ID: this.props.account.account_ID, payed: 1 })
			.then(data => {
				if (data.Status == 1) {
					this.props.accountWasPayed();
				}
				else {
					alert(data.ErrMsg);
				}
			})
			.catch(ex => alert(ex));
	}
};