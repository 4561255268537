import React from "react";
import ApiSrv from "../../ApiSrv";

const Api = new ApiSrv();

export default class EditModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			code: "",
			name: "",
			monthly_budget: 0,
			icon: ""
		};
	}

	componentDidMount() {
		if (this.props.data) {
			this.setState({
				code: this.props.data.category.cat_code,
				name: this.props.data.category.cat_name,
				monthly_budget: this.props.data.category.monthly_budget ? this.props.data.category.monthly_budget : 0,
				icon: this.props.data.category.icon ? this.props.data.category.icon : "",
			});
		}

		// Api.getMethod("accounts", "get_list")
		// 	.then(data => {
		// 		if (data.Status == 1) {
		// 			this.setState({accounts: data.Data});
		// 		}
		// 		else {
		// 			alert(data.ErrMsg);
		// 		}
		// 	})	
		// 	.catch(ex => alert(ex));
	}

	render() {
		return (
			<div className="modal is-active">
				<div className="modal-background"></div>
				<div className="modal-card">
					<header className="modal-card-head">
						<p className="modal-card-title">{this.state.code == "" ? "Add" : "Edit"} Category</p>
						<button className="delete" aria-label="close" onClick={() => this.props.closeMe()}></button>
					</header>
					<section className="modal-card-body">
						<div className="columns">
							<div className="column">
								<input className="input" placeholder="Category Code" type="text" value={this.state.code} onChange={(ev) => this.setState({code: ev.target.value})} />
							</div>							
						</div>

						<div className="columns">
							<div className="column">
								<input className="input" placeholder="Name" value={this.state.name} onChange={(ev) => this.setState({name: ev.target.value})} />
							</div>
							<div className="column">
								<input className="input" placeholder="Monthly Budget" type="number" value={this.state.monthly_budget} onChange={(ev) => this.setState({monthly_budget: ev.target.value})} />
							</div>
						</div>

						<div className="columns">
							<div className="column">
								<input className="input" type="text" placeholder="Icon Name" value={this.state.icon} onChange={(ev) => this.setState({icon: ev.target.value})} />
							</div>
						</div>
						
					</section>
					<footer className="modal-card-foot">
						<button className="button is-success" onClick={() => this.save()}>Save</button>
						<button className="button" onClick={() => this.props.closeMe()}>Close</button>
					</footer>
				</div>
			</div>
		)
	};

	save() {
		let val = true;
		let msg = "";

		if (this.state.name.trim() == "") {
			msg += "- Account name is empty\r";
			val = false;
		}

		if (!val)
			return;

		let p = {
			account_name: this.state.name.trim(),
			active: this.state.active ? 1 : 0
		};

		if (this.state.ID != 0)
			p.ID = this.state.ID;

		if (this.state.cut_off_day != 0)
			p.cut_off_day = this.state.cut_off_day;

		if (this.state.due_day != 0)
			p.due_day = this.state.due_day;

		if (this.state.paying_account_ID != 0)
			p.paying_account_ID = this.state.paying_account_ID;

		if (this.state.image_name.trim() != "")
			p.image_name = this.state.image_name.trim();

		Api.postMethod("accounts", (this.state.ID == 0 ? "insert" : "update") + "_account", p)
			.then(data => {
				if (data.Status == 1) {
					this.props.closeMe(data.Data, this.props.data?.index ? this.props.data?.index : undefined);
				}
				else {
					alert(data.ErrMsg);
				}
			})
			.catch(ex => alert(ex));
	};
};